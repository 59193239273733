<template>
  <b-row class="match-height">
    <b-col lg="8">
      <EditJobSelection />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EditJobSelection from '@/components/company/jobSelection/EditJobSelection.vue'

export default {
  components: {
    BRow,
    BCol,
    EditJobSelection,
  },
}
</script>
