<template>
  <div>
    <h2>選考方法の編集</h2>
    <b-card
      title=""
    >
      <b-form>
        <b-row
          v-for="(item, index) in menulist"
          :key="index"
        >
          <InputParts
            :label="item.label"
            :type="item.type"
            :value="inputForm[item.key]"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            @inputData="inputForm[item.key] = $event"
          />
        </b-row>
        <b-row>
          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              @click="cancel"
            >
              キャンセル
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="confirm"
            >
              登録する
              <b-spinner
                v-if="status === 2"
                small
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BForm, BButton, BCard, BSpinner,
} from 'bootstrap-vue'
import InputParts from '@/components/molecules/input/InputParts.vue'
import { mapGetters } from 'vuex'
import mergeByUID from '@/firestore/data/updateDE'
import menulist from '@/components/conf/JPCAREER様_就労PF_求人企業_選考方法.json'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    InputParts,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {},
      status: 1,
      isShow: false,
      menulist,
    }
  },
  computed: {
    ...mapGetters('jobSelection', ['jobSelectionDic']),
  },
  async mounted() {
    this.inputForm = this.jobSelectionDic
  },
  methods: {
    async confirm() {
      this.status = 2
      const data = {
        collection: 'jobSelection',
        data: this.inputForm,
      }
      const response = await mergeByUID(data)
      if (response.status === 'success') {
        this.success()
      } else {
        window.console.log('error', response.msg)
      }
    },
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'データが正常に登録されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.$router.push({ name: 'job-jobselection' })
        this.status = 1
      })
    },
    cancel() {
      this.$router.push({ name: 'job-jobselection' })
    },
  },
}
</script>
